import { DATABASE_VIEW, VIEW_PAGE, WHITEBOARD_BOARD, EMBED_VIEW } from '@confluence/named-routes';
import { FeedbackSource } from '@confluence/global-feedback-collector';

export enum ContentType {
	WHITEBOARD = 'whiteboard',
	PAGE = 'page',
	BLOGPOST = 'blogpost',
	DATABASE = 'database',
	EMBED = 'embed',
	FOLDER = 'folder',
}

/**
 * Routes that we might redirect from after successfully archiving a piece of content in the tree
 * Full logic for determining this behavior lives in `@confluence/archive-pages`
 */
export const ARCHIVE_SUCCESS_REDIRECT_ROUTES = [
	DATABASE_VIEW.name,
	VIEW_PAGE.name,
	WHITEBOARD_BOARD.name,
	EMBED_VIEW.name,
];

/**
 * Routes that we might redirect from after successfully deleting a parent in the tree
 * while viewing the child content
 * Full logic for determining this behavior lives in `@confluence/bulk-page-actions`
 */
export const DELETE_SUCCESS_REDIRECT_ROUTES = [
	DATABASE_VIEW.name,
	VIEW_PAGE.name,
	WHITEBOARD_BOARD.name,
	EMBED_VIEW.name,
];

export const VALID_OPERATION_TARGETS = [
	ContentType.WHITEBOARD,
	ContentType.DATABASE,
	ContentType.EMBED,
	ContentType.PAGE,
	ContentType.FOLDER,
];

/**
 * Feedback sources for the Global Feedback Collector for each content type
 */
export const CONTENT_TYPE_FEEDBACK_SOURCE = {
	[ContentType.WHITEBOARD]: FeedbackSource.WHITEBOARD,
	[ContentType.EMBED]: FeedbackSource.EMBED,
	[ContentType.DATABASE]: FeedbackSource.DATABASE,
};

/**
 * Resource types where the ARIs include activationIds. This is a pattern
 * that started with Confluence Databases.
 */
export const TYPES_WITH_ACTIVATIONID = ['database', 'embed'];
